<ion-content>
  <div class="bg-light overflow-x-clip">
    <sophus-navbar></sophus-navbar>
    <div class="app-container pt-2 md:pt-16 md:pb-0 lg:pt-32 xl:pt-40 xl:pb-24">
      <div class="grid lg:grid-cols-2 items-center">
        <div class="mb-32 lg:mb-0 xl:mb-16">
          <h1 class="text-4xl">Next-generation nutrition support</h1>
          <p class="text-2xl font-light">
            Sophus is a digital nutrition support solution that combines
            evidence-based nutrition with behavioural insights to achieve
            behaviour change
          </p>
          <button
            class="rounded-md bg-primary text-white py-3 px-5 mt-4"
            appScrollTo="cta"
          >
            Learn More
          </button>
        </div>
        <div class="flex self-stretch">
          <div class="w-full h-full app-home-image"></div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="app-curved-right-light-background pb-0 md:mb-96 lg:mb-0 lg:pb-32"
  ></div>

  <div class="overflow-hidden pb-16 pt-52 md:pt-54 lg:py-32 xl:pt-40">
    <div class="app-container">
      <div class="grid lg:grid-cols-2 items-center lg:gap-16">
        <div class="flex justify-center items-center mt-4 lg:mt-0">
          <div>
            <a href="/medical" routerLink="/medical" (click)="link('medical')">
              <button
                class="rounded-md text-lg text-right w-full bg-primary text-white py-8 px-5 mt-4"
              >
                Health Care Providers
                <ion-icon
                  class="-mb-1"
                  name="chevron-forward-outline"
                ></ion-icon>
              </button>
            </a>
            <a
              href="/employers"
              routerLink="/employers"
              (click)="link('employers')"
            >
              <button
                class="rounded-md text-lg text-right w-full bg-primary text-white py-8 px-5 mt-4"
              >
                Employers
                <ion-icon
                  class="-mb-1"
                  name="chevron-forward-outline"
                ></ion-icon>
              </button>
            </a>
            <a href="/sports" routerLink="/sports" (click)="link('sports')">
              <button
                class="rounded-md text-lg text-right w-full bg-primary text-white py-8 px-5 mt-4"
              >
                Sports Professionals
                <ion-icon
                  class="-mb-1"
                  name="chevron-forward-outline"
                ></ion-icon>
              </button>
            </a>
          </div>
        </div>
        <div class="order-first lg:order-last">
          <h2 class="text-3xl">
            Sophus makes it possible to access nutrition support when and where
            it's needed
          </h2>
          <p>
            Sophus has been built by health professionals in collaboration with
            patients, employers, and coaches to deliver a digital nutrition
            support solution for your patients, employees, or athletes.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-primary pt-16 relative z-20">
    <div class="app-container text-white text-left lg:text-center">
      <h2 class="text-3xl">
        Sophus’ digital solution is the future of dietetic care
      </h2>
      <p class="text-xl text-light font-light mb-0">
        Sophus offers proactive nutrition support for a range of nutrition wants
        and needs, whether it’s lifestyle changes, to improve sporting
        performance, or manage a chronic condition.
      </p>
    </div>
  </div>
  <div class="app-curved-bottom-primary-background pb-16 relative z-20"></div>

  <div
    id="cta"
    class="bg-light -mt-16 pt-32 lg:pt-48 pb-16 lg:pb-32 relative z-10"
  >
    <div class="app-container">
      <div class="mx-auto lg:max-w-md text-left lg:text-center">
        <h2 class="text-3xl">
          Understand how Sophus can work for you and your team
        </h2>
        <p class="">
          To find out more about our range of support and guidance programs,
          contact us today. Enter your details below and our friendly team will
          be in touch.
        </p>
      </div>
      <div class="mx-auto lg:max-w-sm">
        <sophus-cta></sophus-cta>
      </div>
    </div>
  </div>

  <sophus-footer></sophus-footer>
</ion-content>
