import { Component } from "@angular/core";
import { Meta } from "@angular/platform-browser";

@Component({
  selector: "sophus-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent {
  constructor(private meta: Meta) {
    this.meta.addTag({ "prerender-status-code": "404" });
  }
}
