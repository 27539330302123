import { Component } from "@angular/core";
import { PopoverController } from "@ionic/angular";

@Component({
  selector: "sophus-landing-links-popover",
  templateUrl: "./landing-links-popover.component.html",
  styleUrls: ["./landing-links-popover.component.scss"],
})
export class LandingLinksPopoverComponent {
  constructor(private popoverController: PopoverController) {}

  public dismissPopover(): void {
    this.popoverController.dismiss();
  }
}
