import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { take } from "rxjs/operators";

@Component({
  selector: "app-redirect",
  templateUrl: "./redirect.component.html",
  styleUrls: ["./redirect.component.scss"],
})
export class RedirectComponent {
  constructor(private activatedRoute: ActivatedRoute) {}

  public ionViewDidEnter(): void {
    this.activatedRoute.data.pipe(take(1)).subscribe((data) => {
      if (!data.redirect) return;
      window.location.href = data.redirect;
    });
  }
}
