import { ErrorHandler, Injectable } from "@angular/core";
import { NotifyLibService } from "./services/notify/notify-lib.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private messagesLibService: NotifyLibService) {}

  handleError(error: any) {
    error = error?.originalError || error?.error || error;
    let message = error?.message || "Something went wrong!";
    message = message.split("\n");
    message = message[1] || message[0];
    message = message.substring(0, 70);
    this.messagesLibService.showMessage("Error", message, "danger");
  }
}
