<ion-content>
  <div class="bg-light">
    <sophus-navbar></sophus-navbar>
  </div>
  <div class="app-curved-right-light-background py-4"></div>

  <div class="py-28">
    <div class="max-w-2xl mx-auto">
      <div>
        <h2 class="text-3xl">Terms of Service, including Disclaimers</h2>
        <p>
          This document (the "Agreement") is a legally binding agreement between
          you and Health Management Pty Ltd, trading under the name Sophus
          Nutrition, a company registered in Australia under ABN 70 111 745 665
          ("Sophus Nutrition") that governs your use of the online and mobile
          services associated with Sophus Nutrition, including but not limited
          to,
          <a href="http://www.sophus.com.au">www.sophus.com.au</a> and all
          associated subdomains (the "Website"), and the Sophus Nutrition mobile
          application ("App").
        </p>
        <p>
          For ease of reference, all the features and functionality of both the
          App and the Website, together with all its content (whether accessible
          wirelessly, electronically or downloadable for printing), shall be
          referred to herein as the "System".
        </p>
        <p>
          One component of the System is the self-guided nutrition improvement
          program with associated expert education (through articles, visuals
          and videos), meal plans, nudging techniques (SMS & push
          notifications), recipes, journal monitoring and dietitian coaching
          (the "Sophus Nutrition Service"). All terms governing the System also
          apply to the Sophus Nutrition Service. The System and the Sophus
          Nutrition Service are both owned and managed by Sophus Nutrition Pty
          Ltd.
        </p>
        <p>
          Please note that you must read and agree to the terms and conditions
          of this Agreement before you use the System and/or the Sophus
          Nutrition Service. If you do not agree to the terms and conditions of
          the Agreement, you may NOT use the System nor may you use the Sophus
          Nutrition Service.
        </p>
        <p>
          The terms "we", "us", "our" and "ours" when used in these terms mean
          Sophus Nutrition, which includes any parent company, subsidiaries,
          branches or affiliates under common ownership or control of Sophus
          Nutrition. The terms "you", "your" and "yours" when used in these
          terms mean any user of the System.
        </p>
        <p>
          <strong>1. Contract Information</strong>
        </p>
        <p>
          By using the System you confirm that you are 18 years of age or more,
          that any registration information that you submit to Sophus Nutrition
          is true, accurate and complete, that you will update such information
          in order to keep it current, that you have read and agree to the terms
          and conditions contained in this Agreement and our Privacy Policy, and
          that you shall be legally bound by such terms subject to all
          applicable laws and regulations.
        </p>
        <p>
          <strong>2. Understanding Sophus Nutrition</strong>
        </p>
        <p>
          You acknowledge and agree that the Sophus Nutrition Service is a
          personalized self-help system designed to help you improve your own
          wellbeing and that if you choose to access the Sophus Nutrition
          Service you are solely responsible for deciding which of the suggested
          techniques you put into practice and how to apply those techniques.
        </p>
        <p>
          You also acknowledge and agree that Sophus Nutrition is not a medical
          organisation and that the Sophus Nutrition Service is not intended to
          diagnose, treat or otherwise address any medical problem. The material
          on the System, whether posted by Sophus Nutrition employees, or other
          users, is provided for your information and not as medical advice and
          should not be seen as a replacement for consultation with a doctor or
          other qualified care professional. You are urged to seek the advice of
          a doctor before beginning any program. If you receive advice from a
          doctor or other qualified medical professional which conflicts with
          anything contained in the System, then the former should take
          precedence. The Sophus Nutrition Service, the App and this Website are
          not intended for use by minors, or pregnant women. Individuals with
          any type of health condition are specifically warned to seek
          professional medical advice prior to beginning any Sophus program.
        </p>
        <p>
          <strong>3. Grant of right of use</strong>
        </p>
        <p>
          The System is only available to either individuals who enter their
          card details, or who have had the Service commissioned for them by a
          third party partner (e.g. PAH Clinical Trial, or their employer)
          ("Paid for Services"). Unless otherwise specified, we grant you a
          limited, non-exclusive, non-transferable, revocable right to make
          personal non-commercial use only of that content and those features of
          the System and Sophus Nutrition Service in respect of which you or the
          third party have paid all applicable rental fees and charges, provided
          that you comply fully with the provisions of this Agreement.
        </p>
        <p>
          <strong>4. Prices and payment</strong>
        </p>
        <p>
          If paying for your own program, within the first 14 days of your
          Sophus Nutrition start date, you may cancel your subscription to
          receive a full refund via email to hello@sophus.com.au.
        </p>
        <p>Contract Length:</p>
        <p>
          Through signing up to the Sophus Nutrition dietitian coaching, you are
          signing up for a 1-month minimum contract. Your subscription will
          renew on a monthly basis for dietitian coaching. You can cancel your
          subscription by contacting hello@sophus.com.au. Once you have
          cancelled, at the end of your subscription your account will be
          disabled and you will no longer be able to access dietitian coaching.
        </p>
        <p>
          <strong>5. Changes to the Agreement</strong>
        </p>
        <p>
          Sophus Nutrition reserves the right to vary this Agreement from time
          to time by amending this page. Registered user's will be notified of
          changes by the email address we have for you on file. Any material
          amendments intending to bind an existing user of the System shall
          become effective 7 days after the email.
        </p>
        <p>
          <strong>6. Your health and Sophus Nutrition</strong>
        </p>
        <p>
          You are urged and advised to seek the advice of a doctor before
          beginning any lifestyle improvement program. If you have any other
          concerns or questions about your health or medicines, you should
          always consult an appropriate healthcare professional. Be cautious
          about embarking on vigorous exercise if you have any serious medical
          condition, including (but not limited to) back pain, high blood
          pressure or heart disease, or if you are pregnant. Always seek
          professional medical advice when contemplating any changes in your
          prescribed medicines.
        </p>
        <p>
          This program is intended as a lifestyle change guide and is therefore
          not classified as a medical device, meaning Sophus Nutrition should
          not replace any existing recommendations that have been given by a
          healthcare professional. The content is not intended to be a
          substitute for professional medical advice, support, diagnosis, or
          treatment. As such, the programs available through Sophus Nutrition
          may not be suitable for every individual. You should always seek the
          advice of your healthcare professional with any questions you may have
          regarding any underlying / existing medical condition before starting
          with Sophus Nutrition.
        </p>
        <p>
          A team of dietitians have provided advice for dietary information
          provided on the System and contained within the Sophus Nutrition
          Service. Care has been taken to confirm that the information presented
          by authors is accurate and describes generally accepted practices. If
          we find, or are alerted to, a mistake, we will correct it as quickly
          as possible. However, to the fullest extent permitted by applicable
          laws, the authors, editors and publishers are not responsible for
          errors or omissions, or for any consequences arising from application
          of the information on the System, and make no warranty, express or
          implied, with respect to the contents, completeness or accuracy of the
          material published.
        </p>
        <p>
          <strong>7. Privacy</strong>
        </p>
        <p>
          We process information about you in accordance with our
          <a routerLink="/privacy">Privacy Policy</a>.
        </p>
        <p>
          <strong>8. Intellectual Property Rights</strong>
        </p>
        <p>
          The layout, design, content and graphics on the System and the Sophus
          Nutrition Service are protected by Australian and other international
          copyright laws. Unless expressly permitted in writing and other than
          printing a small proportion of content or displaying this on your
          screen or both, (strictly for your personal non-commercial use), no
          part of the System may be reproduced or stored in any medium,
          including but not limited to a retrieval system, or transmitted, in
          any form or by any means (electronic, mechanical, photocopying,
          recording, broadcasting), nor shown in public. You may not create any
          derivative work or make any other adaptation, without our prior
          written consent. You must not modify the copies you have displayed or
          printed in any way and you may not use any illustrations, photographs,
          videos or audio sequences or any graphics separately from any
          accompanying text.
        </p>
        <p>
          If you print off or download any material from the System in breach of
          these terms of use, your right to use the System and the Sophus
          Nutrition Service will cease immediately and you must at our option
          return or destroy any copies of the materials you have made. All
          rights not expressly granted in these terms or any express written
          license, are reserved. For all other uses of our content or images you
          must contact us and obtain our prior written permission.
        </p>
        <p>
          <strong>9. Prohibited uses</strong>
        </p>
        <p>You agree that you will not use the System to:</p>
        <ul data-rte-list="default">
          <li>
            <p>
              Upload, post, email or otherwise transmit any content or materials
              that are unlawful, harmful, threatening, abusive, harassing,
              tortious, defamatory, vulgar, obscene, libelous, invasive of
              another's privacy, hateful, or racially, ethnically or otherwise
              objectionable, at our sole and absolute discretion;
            </p>
          </li>
          <li>
            <p>
              Harm minors in any way, or solicit or otherwise attempt to gain
              any information from a minor;
            </p>
          </li>
          <li>
            <p>
              Impersonate any person or entity, including, but not limited to,
              any user of this System, a director, officer, employee,
              shareholder, agent or representative of Sophus Nutrition or our
              affiliates, or any other person or entity, or falsely state or
              otherwise misrepresent your affiliation with Sophus Nutrition, our
              affiliates or any other person or entity
            </p>
          </li>
          <li>
            <p>
              Forge headers or otherwise manipulate identifiers in order to
              disguise the origin of any Postings or other materials transmitted
              to or through the System
            </p>
          </li>
          <li>
            <p>
              Upload, post, email or otherwise transmit any materials that are
              not your own, or that you do not have a right to upload, post,
              email or otherwise transmit under any law or under contractual or
              fiduciary relationships (such as insider information, proprietary
              and confidential information learned or disclosed as part of
              employment relationships or under nondisclosure agreements)
            </p>
          </li>
          <li>
            <p>
              Upload, post, email or otherwise transmit any content or other
              materials that infringe upon any patent, trademark, trade secret,
              copyright, right of privacy or publicity or other proprietary
              rights of any party
            </p>
          </li>
          <li>
            <p>
              Upload, post, email or otherwise transmit any unsolicited or
              unauthorized advertising, promotional materials, "junk mail,"
              "spam," "chain letters," "pyramid schemes," or any other form of
              commercial solicitation except in the areas, if any that are
              specifically designated for such purpose
            </p>
          </li>
          <li>
            <p>
              Upload, post, email or otherwise transmit any content or other
              materials that contain software viruses or any other computer
              code, files or programs designed to interrupt, destroy or limit
              the functionality of any computer software or hardware or
              telecommunications equipment
            </p>
          </li>
          <li>
            <p>
              Disrupt the normal flow of dialog, cause a screen to "scroll"
              faster than other users of the System are able to type, or
              otherwise act in a manner that negatively affects or otherwise
              diminishes the quality of another user's experience of this System
            </p>
          </li>
          <li>
            <p>
              Interfere with or disrupt the System or servers or networks
              connected to the System, or disobey any requirements, procedures,
              policies or regulations of networks connected to the System
            </p>
          </li>
          <li>
            <p>
              Intentionally or unintentionally violate any applicable laws
              and/or regulations
            </p>
          </li>
          <li>
            <p>
              "Stalk" or otherwise harass another user of the System and/or any
              employee of Sophus Nutrition
            </p>
          </li>
          <li>
            <p>
              Solicit, collect or post personal data or attempt to solicit,
              collect or post personal data about other users of the Website
              from the Website (including user names or passwords) or about any
              other third party, and/or:
            </p>
            <ul data-rte-list="default">
              <li>
                <p>
                  Access or attempt to access another user's account without his
                  or her consent.
                </p>
              </li>
              <li>
                <p>
                  Knowingly transmit any data, send or upload any material that
                  contains viruses, Trojan horses, worms, time-bombs, keystroke
                  loggers, spyware, adware or any other harmful programs or
                  similar computer code designed to adversely affect the
                  operation of any computer software or hardware.
                </p>
              </li>
            </ul>
          </li>
        </ul>
        <p>
          <strong>You also agree not to:</strong>
        </p>
        <ul data-rte-list="default">
          <li>
            <p>
              Reproduce, duplicate, copy or re-sell any part of the System in
              contravention of the provisions of this Agreement.
            </p>
          </li>
          <li>
            <p>Resell access to the System.</p>
          </li>
          <li>
            <p>Frame the System or any part of it.</p>
          </li>
          <li>
            <p>
              Copy any material obtained from the System to peer to peer
              networks or other web sites or web services.
            </p>
          </li>
          <li>
            <p>
              Access without authority, interfere with, damage or disrupt any
              part of the System; any equipment or network on which the System
              is stored; any software used in the provision of the System; or
              any equipment or network or software owned or used by any third
              party.
            </p>
          </li>
        </ul>
        <p>
          <strong>10. Breach of the Agreement</strong>
        </p>
        <p>
          We will determine, in our absolute discretion, whether there has been
          a breach of this Agreement through your use of the System. When a
          breach of this Agreement has occurred, we may take such action as we
          deem appropriate, which may include all or any of the following
          actions:
        </p>
        <ul data-rte-list="default">
          <li>
            <p>
              Immediate, temporary or permanent withdrawal of your right to use
              the System and/or the Sophus Nutrition Service.
            </p>
          </li>
          <li>
            <p>
              Immediate, temporary or permanent removal of any posting or
              material uploaded by you to the System and/or the Sophus Nutrition
              Service.
            </p>
          </li>
          <li>
            <p>
              Issue a warning notice to you to immediately cease and/or remedy
              such breach and following receipt such action should be taken
              immediately.
            </p>
          </li>
          <li>
            <p>
              Legal proceedings against you for reimbursement of all costs on an
              indemnity basis (including, but not limited to, reasonable
              administrative and legal costs) resulting from the breach.
            </p>
          </li>
          <li>
            <p>Any further legal action against you.</p>
          </li>
          <li>
            <p>
              Disclosure of such information to law enforcement authorities as
              we reasonably feel is necessary.
            </p>
          </li>
        </ul>
        <p>
          We exclude liability for actions taken in response to breaches of this
          Agreement. The possible actions we may take on breach of this
          Agreement are not limited to those described here, and we may take any
          other action we reasonably deem appropriate.
        </p>
        <p>
          <strong>11. No warranty</strong>
        </p>
        <p>
          The use of the System and Sophus Nutrition Service (including but not
          limited to their content and features) is at your own risk. The System
          is provided on an "as is" and "as available" basis. To the extent
          permitted by applicable law, Sophus Nutrition gives no warranty,
          express or implied, as to the quality, content and availability or
          fitness for a specific purpose of the System or the Sophus Nutrition
          Service or to the accuracy of the information contained in any of the
          materials on the System or the Sophus Nutrition Service. Sophus
          Nutrition shall not be liable to any person for any loss or damage of
          any kind, which may arise, directly or indirectly from the use of or
          inability to use any of the information contained in any of the
          materials on the System. There is no guarantee of availability of
          information on the System at any time, nor that it is up to date or
          error-free. As with any purchase of a product or service through any
          medium or in any environment, you should use your judgment and
          exercise caution where appropriate. No advice or information whether
          oral or in writing obtained by you from Sophus Nutrition shall create
          any warranty on behalf of Sophus Nutrition in this regard. Certain
          jurisdictions do not allow the exclusion or disclaimer of certain
          warranties. Accordingly, some of the above disclaimers may not apply
          to you. Your statutory rights as a consumer, if any, are not affected
          hereby.
        </p>
        <p>
          <strong>12. Limitation of liability</strong>
        </p>
        <p>
          To the fullest extent applicable permitted by applicable laws, Sophus
          Nutrition, its affiliates, officers, directors, employees, licensors
          or any third parties exclude liability for any direct, indirect,
          incidental, special or consequential damages (including but not
          limited to any loss of data, service interruption, computer failure or
          pecuniary loss, loss of income or revenue, loss of business, loss of
          profits or contracts, loss of anticipated savings, loss of data, loss
          of goodwill, wasted management or office time and for any other loss
          or damage of any kind, however arising and whether caused by tort
          (including negligence), breach of contract or otherwise, even if
          foreseeable) arising out of the use of or inability to use the System
          or Sophus Nutrition Service, even if you have advised Sophus Nutrition
          about the possibility of such loss, and including any damages
          resulting therefrom. Commentary and other materials posted on the
          System by other users are not intended to amount to advice on which
          reliance should be placed. We therefore, to the fullest extent
          permitted by applicable laws, disclaim all liability and
          responsibility arising from any reliance placed on such materials by
          any visitor to the System, or by anyone who may be informed of any of
          its contents. If any part of this limitation of liability is found to
          be invalid or unenforceable for any reason, then the aggregate
          liability of Sophus Nutrition (including any of its affiliates,
          service providers and licensors) under such circumstance for
          liabilities that otherwise would have been limited, shall not exceed
          the maximum single purchase price of the Sophus Nutrition Service. Any
          claims arising out of or in connection with your use of the System
          must be brought within one year of the date of the event giving rise
          to such action occurred.
        </p>
        <p>
          Nothing in this provision affects our or our contractors' liability
          for death or personal injury arising from our (or their) negligence
          nor our (or their) liability for fraudulent misrepresentation or
          misrepresentation as to a fundamental matter nor any other liability
          which cannot be excluded or limited under an applicable law.
        </p>
        <p>
          <strong>13. Technology limitations and modifications</strong>
        </p>
        <p>
          Sophus Nutrition will make reasonable efforts to keep the System
          operational. However, certain technical difficulties, maintenance, or
          any other cause beyond our reasonable control (including (but not
          limited to) failure of the internet, natural disaster, labor shortage
          or dispute, or governmental act) may, from time to time, result in
          interruptions. Sophus Nutrition reserves the right at any time and
          from time to time to modify or discontinue, temporarily or
          permanently, functions and features of the System with or without
          notice. You agree not to hold us liable for any such failure or delay
          in performance and we agree to make all reasonable efforts to restore
          the System (or any part thereof) as soon as practicable. Following any
          disruption within Sophus Nutrition's reasonable control we shall
          extend your access to the Paid for Services for at least the duration
          for which those Paid for Services were inaccessible on written request
          to the below address.
        </p>
        <p>
          <strong>14. Third parties</strong>
        </p>
        <p>
          Certain hypertext links in this site may lead to other third party
          websites, which are not under the control of Sophus Nutrition. When
          you activate any of these you will leave the System and Sophus
          Nutrition has no control over, and will accept no responsibility or
          liability, for the material on any website which is not under the
          control of Sophus Nutrition. Sophus Nutrition does not make any
          representations or give any guarantee or warranties of any kind,
          expressed, implied or otherwise about the content of on any external
          website link.
        </p>
        <p>
          <strong>15. Indemnity</strong>
        </p>
        <p>
          You agree to indemnify and hold Sophus Nutrition and its officers,
          directors, employees and licensors harmless from any claim or demand
          (including but not limited to reasonable legal fees) made by a third
          party due to or arising out of or related to your violation of the
          terms and conditions of this Agreement or your violation of the System
          (and all related materials) or any applicable laws, regulations or
          third party rights.
        </p>
        <p>
          <strong>16. Assignment by Sophus Nutrition</strong>
        </p>
        <p>
          Sophus Nutrition may assign (or otherwise transfer) this Agreement or
          any part of it without restrictions. You may not assign (or otherwise
          transfer) this Agreement or any part of it to any third party.
        </p>
        <p>
          <strong>17. Entire agreement</strong>
        </p>
        <p>
          This Agreement (incorporating these terms and the Privacy Policy as
          may be updated from time to time) constitutes all the terms and
          conditions agreed upon between you and Sophus Nutrition and supersedes
          any prior agreements in relation to the subject matter of this
          Agreement, whether written or oral. Any additional or different terms
          or conditions in relation to the subject matter of this Agreement in
          any written or oral communication from you to Sophus Nutrition are
          void. You agree and accept that you have not accepted the terms and
          conditions of this Agreement in reliance of or to any oral or written
          representations made by Sophus Nutrition not contained in this
          Agreement.
        </p>
        <p>
          <strong>18. Severability and waiver</strong>
        </p>
        <p>
          If any provision of the Agreement is held by a court of competent
          jurisdiction to be unlawful, void or for any reason unenforceable,
          such provision shall be changed and interpreted to best accomplish the
          objectives of the original provision fully permitted by law, and the
          remaining provisions of the Agreement shall remain in full force and
          effect.
        </p>
        <p>
          If we fail to insist that you perform any of your obligations under
          these terms, or if we do not enforce our rights against you, or if we
          delay in doing so, that will not mean that we have waived our rights
          against you and will not mean that you do not have to comply with
          those obligations. If we do waive a default by you, we will only do so
          in writing, and that will not mean that we will automatically waive
          any later default by you.
        </p>
        <p>
          <strong>19. Term and termination</strong>
        </p>
        <p>
          This Agreement is between you and us only and will become effective
          when you start using the System and will remain effective until
          terminated by you or Sophus Nutrition. Sophus Nutrition reserves the
          right to terminate this Agreement or suspend your Sophus Nutrition
          account at any time in case of unauthorized, or suspected unauthorized
          use of the System whether in contravention of this Agreement or
          otherwise. If Sophus Nutrition terminates this Agreement, or suspends
          your Sophus Nutrition account, for any of the reasons set out in this
          section, Sophus Nutrition shall have no liability or responsibility to
          you whatsoever.
        </p>
        <p>
          <strong>20. Governing law and disputes</strong>
        </p>
        <p>
          The System is controlled by Sophus Nutrition from its offices in
          Australia. Access to, or use of, the System, including the Sophus
          Nutrition Service and any related information and materials, may be
          prohibited by law in certain jurisdictions. You are responsible for
          compliance with all applicable laws of the jurisdiction from which you
          are accessing the System. We make no representation that the
          information contained herein is appropriate or available for use in
          other locations.
        </p>
        <p>
          This Agreement and any contract between us, whether for use of the
          System or other purpose, and any non-contractual obligations (if any)
          arising out of or about these terms and conditions or any such
          contract will be governed by Australian law. The parties agree that
          the courts of Australia have exclusive jurisdiction to settle any
          dispute arising out of or about this Agreement (including a dispute
          regarding the existence, validity or termination of this Agreement or
          any non-contractual obligation arising out of or about this
          Agreement). Notwithstanding the foregoing, we reserve the right to
          bring legal proceedings in any jurisdiction where we believe a breach
          of this Agreement has originated.
        </p>
        <p>
          <strong>21. Survivorship</strong>
        </p>
        <p>
          The following provisions shall survive termination of this Agreement:
          Clause 8 (Intellectual Property), Clause 12 (Limitation), Clause 15
          (Indemnity), Clause 17 (Entire Agreement), Clause 18 (Severability and
          waiver) and Clause 20 (Governing Law and Disputes).
        </p>
        <p>
          <strong>23. Contact</strong>
        </p>
        <p>You can contact us via the following details:</p>
        <ul data-rte-list="default">
          <li>
            <p>Email: hello@sophus.com.au</p>
          </li>
          <li>
            <p>Post: Sophus Nutrition, 4/194 McLeod St, Cairns, QLD, 4870</p>
          </li>
        </ul>
        <p>
          Please note that we are unable to answer any enquiries requesting
          medical advice. Such enquiries should be addressed to an appropriate,
          qualified health practitioner.
        </p>
      </div>
    </div>
  </div>

  <div id="cta" class="bg-light py-28">
    <div class="app-container">
      <div class="max-w-sm mx-auto text-center">
        <h2 class="text-3xl">Understand how Sophus can work for your team</h2>
        <p>
          To find out more about our range of support and guidance programs, get
          in touch with us today. Enter your details below and we’ll be in
          touch.
        </p>
        <sophus-cta></sophus-cta>
      </div>
    </div>
  </div>

  <sophus-footer></sophus-footer>
</ion-content>
