import { Component, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { LoadingController } from "@ionic/angular";
import { NotifyLibService } from "@sophus/notify";
import { finalize } from "rxjs/operators";
import { SharedService } from "../../services/shared/shared.service";

@Component({
  selector: "sophus-cta",
  templateUrl: "./cta.component.html",
  styleUrls: ["./cta.component.scss"],
})
export class CtaComponent {
  public enquiry: any;

  @ViewChild("form")
  public form?: NgForm;

  constructor(
    private sharedService: SharedService,
    private loadingCtrl: LoadingController,
    private notifyService: NotifyLibService,
    private router: Router
  ) {
    this.enquiry = {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      url: this.router.url,
    };
  }

  public async handleSubmit() {
    if (!this.form?.valid) return;
    const loading = await this.loadingCtrl.create();
    await loading.present();
    this.sharedService
      .callback(this.enquiry)
      .pipe(finalize(() => loading.dismiss()))
      .subscribe(() => {
        this.form?.resetForm();
        this.notifyService.showMessage(
          "Message received! We'll be in touch soon.",
          "secondary"
        );
      });
  }
}
