import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "sophus-mobile-menu",
  templateUrl: "./mobile-menu.component.html",
  styleUrls: ["./mobile-menu.component.scss"],
})
export class MobileMenuComponent implements OnInit {
  constructor(private modalController: ModalController) {}

  ngOnInit(): void {}

  public closeMobileMenu() {
    this.modalController.dismiss();
  }
}
