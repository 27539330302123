import { NgModule } from "@angular/core";
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterEvent,
  RouterModule,
  Routes,
} from "@angular/router";
import { filter } from "rxjs/operators";
import { AboutComponent } from "./pages/components/about/about.component";
import { ClientsComponent } from "./pages/components/clients/clients.component";
import { EmployersComponent } from "./pages/components/employers/employers.component";
import { HomeComponent } from "./pages/components/home/home.component";
import { MedicalComponent } from "./pages/components/medical/medical.component";
import { PrivacyComponent } from "./pages/components/privacy/privacy.component";
import { SportsComponent } from "./pages/components/sports/sports.component";
import { TermsComponent } from "./pages/components/terms/terms.component";
import { NotFoundComponent } from "./shared/components/not-found/not-found.component";
import { RedirectComponent } from "./shared/components/redirect/redirect.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    data: {
      title: "Sophus | Next-Generation Nutrition Support",
      description: `Sophus is a digital nutrition support solution that combines evidence-based nutrition with behavioural insights to achieve behaviour change.`,
      image: "/home.png",
      url: "/",
    },
  },
  {
    path: "medical",
    component: MedicalComponent,
    data: {
      title: "Healthcare Providers | Sophus",
      description: `Sophus provides your patients with expert nutrition advice through our suite of digital nutrition programs that help your patients manage their health, from lifestyle goals to chronic conditions.`,
      image: "/medical.png",
      url: "/medical",
    },
  },
  {
    path: "employers",
    component: EmployersComponent,
    data: {
      title: "Employers | Sophus",
      description: `Sophus is designed to provide a tailored approach to corporate nutrition – so your team, wherever they are, can perform at their best.`,
      image: "/employers.png",
      url: "/employers",
    },
  },
  {
    path: "sports",
    component: SportsComponent,
    data: {
      title: "Sports Professionals | Sophus",
      description: `Support your athlete’s health and performance with instant evidence-based and practically trialled nutrition support.`,
      image: "/sports.png",
      url: "/sports",
    },
  },
  {
    path: "about",
    component: AboutComponent,
    data: {
      title: "About Us | Sophus",
      description: `We’ve created a digital support solution for delivering better health outcomes at lower costs for those who want to make lifestyle changes, or those who are managing chronic conditions.`,
      image: "/assets/images/tara-profile.png",
      url: "/about",
    },
  },
  {
    path: "experience",
    component: ClientsComponent,
    data: {
      title: "Client Experience | Sophus",
      description: `With Sophus, clients get expert information coupled with the support to make real changes. See how we help people create behaviour change, for good.`,
      image: "/client-experience.png",
      url: "/experience",
    },
  },
  {
    path: "privacy",
    component: PrivacyComponent,
    data: {
      title: "Privacy Policy | Sophus",
      description: `Sophus takes your privacy very seriously and treats all your personal data with great care.`,
      image: "/home.png",
      url: "/privacy",
    },
  },
  {
    path: "terms",
    component: TermsComponent,
    data: {
      title: "Terms of Service | Sophus",
      description: `Describes the legally binding agreement governing the terms of service of the Sophus platform.`,
      image: "/home.png",
      url: "/terms",
    },
  },
  {
    path: "implementation",
    component: RedirectComponent,
    data: {
      redirect: "https://resources.sophus.com.au/implementation",
    },
  },
  {
    path: "resourceportal",
    component: RedirectComponent,
    data: {
      redirect: "https://resources.sophus.com.au/resourceportal",
    },
  },
  {
    path: "recipe-book-download",
    component: RedirectComponent,
    data: {
      redirect: "https://resources.sophus.com.au/recipe-book-download",
    },
  },
  {
    path: "tyhgkwjtn57hyk",
    component: RedirectComponent,
    data: {
      redirect: "https://resources.sophus.com.au/tyhgkwjtn57hyk",
    },
  },
  {
    path: "qldbusiness",
    component: RedirectComponent,
    data: {
      redirect: "https://resources.sophus.com.au/qldbusiness",
    },
  },
  {
    path: "phone-numbers",
    component: RedirectComponent,
    data: {
      redirect: "https://resources.sophus.com.au/phone-numbers",
    },
  },
  {
    path: "404",
    component: NotFoundComponent,
    data: {
      title: "Not Found | Sophus",
    },
  },
  {
    path: "**",
    redirectTo: "404",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabled",
      scrollPositionRestoration: "top", // doesnt work with ion-router-outlet - see below
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(router: Router) {
    router.events
      .pipe(
        filter((e: any) => {
          return e instanceof NavigationStart || e instanceof NavigationEnd;
        })
      )
      .subscribe({
        next: (e: RouterEvent) => {
          if (e instanceof NavigationEnd) {
            const contents = document.querySelectorAll("ion-content");
            contents.forEach((each) =>
              setTimeout(() => each?.scrollToTop(), 0)
            );
          }
        },
      });
  }
}
