<div class="py-8">
  <div class="app-container">
    <div class="flex items-center justify-between">
      <div>
        <a href="/" routerLink="/">
          <img
            class="cursor-pointer"
            src="../../../../assets/images/logo.svg"
            alt="Sophus Nutrition Logo"
            style="height: 40px"
          />
        </a>
      </div>
      <div>
        <ul class="hidden lg:flex items-center">
          <li
            class="py-2 px-4 text-primary cursor-pointer"
            (click)="openLandingLinksPopover($event)"
          >
            Why Sophus
          </li>
          <li>
            <a
              class="py-2 px-4 text-primary"
              href="/experience"
              routerLink="/experience"
            >
              Client Experience
            </a>
          </li>
          <li>
            <a class="py-2 px-4 text-primary" href="/about" routerLink="/about">
              About Us
            </a>
          </li>

          <li>
            <a
              class="py-2 px-4 text-primary"
              href="https://research.sophus.com.au"
              target="_blank"
            >
              Research
            </a>
          </li>

          <button
            appScrollTo="cta"
            class="rounded-md bg-primary text-white px-5 py-3 ml-2"
          >
            Book Demo
          </button>
        </ul>
        <div class="flex items-center">
          <ion-icon
            (click)="openMobileMenu()"
            class="lg:hidden text-primary text-3xl"
            name="menu-outline"
          ></ion-icon>
        </div>
      </div>
    </div>
  </div>
</div>
