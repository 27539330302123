import { HttpClient } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { Browser } from "@capacitor/browser";
import {
  LocalNotifications,
  ScheduleResult,
} from "@capacitor/local-notifications";
import {
  PushNotifications,
  PushNotificationSchema,
  Token,
} from "@capacitor/push-notifications";
import { ToastController } from "@ionic/angular";
import { Environment } from "@sophus/shared";

@Injectable({
  providedIn: "root",
})
export class NotifyLibService {
  public loading: boolean;
  public determinate: boolean;
  public progress: number;

  constructor(
    private toastController: ToastController,
    private router: Router,
    private http: HttpClient,
    private env: Environment,
    private zone: NgZone
  ) {
    this.loading = false;
    this.determinate = false;
    this.progress = 0;
  }

  public async showMessage(
    header: string,
    message: string,
    color: string = "primary",
    duration: number = 3000,
    position: "top" | "bottom" | "middle" = "bottom"
  ): Promise<void> {
    const toast = await this.toastController.create({
      header,
      message,
      color,
      duration,
      position,
      buttons: [{ icon: "close", role: "cancel" }],
    });
    return toast.present();
  }

  public setLoading(value: boolean): void {
    this.loading = value;
  }

  public async checkNotifications(): Promise<boolean> {
    const result = await PushNotifications.checkPermissions();
    return result.receive === "granted";
  }

  public async requestNotifications(): Promise<boolean> {
    const result = await PushNotifications.requestPermissions();
    return result.receive === "granted";
  }

  public async registerNotifications(): Promise<Token> {
    const permit = await this.requestNotifications();
    if (!permit) throw new Error("No permissions for notifications");
    return new Promise((res, rej) => {
      PushNotifications.addListener("registration", res);
      PushNotifications.addListener("registrationError", rej);
      return PushNotifications.register();
    });
  }

  public listenNotications(): void {
    PushNotifications.addListener(
      "pushNotificationReceived",
      this.showLocalNotification.bind(this)
    );
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      this.handleNotificationAction.bind(this)
    );
    LocalNotifications.addListener(
      "localNotificationActionPerformed",
      this.handleNotificationAction.bind(this)
    );
  }

  public showLocalNotification(
    push: PushNotificationSchema
  ): Promise<ScheduleResult> {
    const { title, body, data } = push;
    return LocalNotifications.schedule({
      notifications: [{ title, body, id: 0, extra: data }],
    });
  }

  public handleNotificationAction(action: any) {
    this.zone.run(() => {
      const notification: any = action?.notification;
      const data = notification?.data || notification?.extra || {};
      if (data?.type === "content") {
        const program = data?.programId;
        const content = data?.contentId;
        if (!program || !content) return;
        this.router.navigate(["programs", program, "contents", content]);
      }
      if (data?.type === "message") {
        this.router.navigate(["messages"]);
      }
      if (data?.type === "news") {
        const url = data?.link;
        if (!url) return;
        Browser.open({ url });
      }
    });
  }
}
