import { Component, OnInit } from "@angular/core";
import { ModalController, PopoverController } from "@ionic/angular";
import { LandingLinksPopoverComponent } from "../landing-links-popover/landing-links-popover.component";
import { MobileMenuComponent } from "../mobile-menu/mobile-menu/mobile-menu.component";

@Component({
  selector: "sophus-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  constructor(
    private popoverController: PopoverController,
    private modalController: ModalController
  ) {}

  ngOnInit(): void {}

  public async openMobileMenu() {
    const modal = await this.modalController.create({
      component: MobileMenuComponent,
      cssClass: "mobile-menu",
    });
    return await modal.present();
  }

  public async openLandingLinksPopover(event: Event) {
    const popover = await this.popoverController.create({
      component: LandingLinksPopoverComponent,
      event: event,
    });
    await popover.present();
  }
}
