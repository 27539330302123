<div class="bg-white py-4 px-1">
  <ul class="">
    <li>
      <p>
        <a
          class="py-2 px-4 text-primary"
          routerLink="/medical"
          (click)="dismissPopover()"
        >
          Health Care Providers
        </a>
      </p>
    </li>
    <li>
      <p>
        <a
          class="py-2 px-4 text-primary"
          routerLink="/employers"
          (click)="dismissPopover()"
        >
          Employers
        </a>
      </p>
    </li>
    <li>
      <p class="mb-0">
        <a
          class="py-2 px-4 text-primary"
          routerLink="/sports"
          (click)="dismissPopover()"
        >
          Sports Professionals
        </a>
      </p>
    </li>
  </ul>
</div>
