<form #form="ngForm" (ngSubmit)="handleSubmit()">
  <ion-grid class="app-cta-form">
    <ion-row>
      <ion-col size="12"
        ><ion-item class="app-input mt-0" lines="full">
          <ion-input
            name="firstName"
            [(ngModel)]="enquiry.firstName"
            #firstName="ngModel"
            placeholder="First Name"
            required
          ></ion-input>
        </ion-item>
        <ion-item
          *ngIf="firstName.invalid && (firstName.dirty || form.submitted)"
          lines="none"
        >
          <ion-text *ngIf="firstName.errors?.required" color="danger">
            First name is required
          </ion-text>
        </ion-item></ion-col
      >
      <ion-col size="12"
        ><ion-item class="app-input mt-0" lines="full">
          <ion-input
            name="lastName"
            [(ngModel)]="enquiry.lastName"
            #lastName="ngModel"
            placeholder="Last Name"
            required
          ></ion-input>
        </ion-item>
        <ion-item
          *ngIf="lastName.invalid && (lastName.dirty || form.submitted)"
          lines="none"
        >
          <ion-text *ngIf="lastName.errors?.required" color="danger">
            Last name is required
          </ion-text>
        </ion-item></ion-col
      >
      <ion-col size="12">
        <ion-item class="app-input mt-0" lines="full">
          <ion-input
            name="email"
            type="email"
            [(ngModel)]="enquiry.email"
            #email="ngModel"
            placeholder="Email Address"
            required
            pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]*"
          ></ion-input>
        </ion-item>
        <ion-item
          *ngIf="email.invalid && (email.dirty || form.submitted)"
          lines="none"
        >
          <ion-text *ngIf="email.errors?.required" color="danger">
            Email address is required
          </ion-text>
          <ion-text *ngIf="email.errors?.pattern" color="danger">
            Must be a valid email address
          </ion-text>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item class="app-input mt-0" lines="full">
          <ion-input
            name="mobile"
            type="tel"
            [(ngModel)]="enquiry.mobile"
            #mobile="ngModel"
            placeholder="Mobile Number"
            required
          ></ion-input>
        </ion-item>
        <ion-item
          *ngIf="mobile.invalid && (mobile.dirty || form.submitted)"
          lines="none"
        >
          <ion-text *ngIf="mobile.errors?.required" color="danger">
            Mobile number is required
          </ion-text>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <div class="mx-2">
          <button
            type="submit"
            class="rounded-md bg-primary text-white text-sm p-3 mt-4 w-full"
          >
            Talk With A Sophus Representative
          </button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
