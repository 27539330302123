<ion-content>
  <div class="bg-light">
    <sophus-navbar></sophus-navbar>
    <div class="app-container pt-20 pb-8">
      <div class="">
        <h1 class="text-4xl">
          Our mission is to make accessing evidence-based nutrition support
          easier, practical, and more affordable.
        </h1>
        <p class="text-xl font-light">
          We’ve created a digital support solution for delivering better health
          outcomes at lower costs for those who want to make lifestyle changes,
          or those who are managing chronic conditions.
        </p>
      </div>
    </div>
  </div>
  <div class="app-curved-right-light-background py-16"></div>

  <div class="py-28 overflow-x-clip">
    <div class="app-container">
      <div class="grid md:grid-cols-2 gap-24 items-start">
        <div class="">
          <h2 class="text-3xl">Why we created Sophus</h2>
          <p>
            I was first interested in how technology could help my patients back
            in 2008. I had a high-volume practice and was completing over 10,000
            dietitian consultations a year myself, and had about 7 dietitians
            working in my practice. I sought help to build two apps – a eating
            trainer app, to help normalise eating speed in my eating disorder
            and bariatric clients, and a photo food app, so I could have a more
            accurate understanding of what my clients ate. This was iPhone 3
            days, and after the first few iterations believed (obviously
            incorrectly now), that the smartphone fad would not catch on.
          </p>
          <p>
            I continued to see that there was a disconnect between what my
            patients wanted from me, and what I could deliver without
            technology. I was (and still am) just one person. I had gone on to
            do further training in psychology, consumer behaviour and
            behavioural economics and realised that how I was working, may not
            work to help my clients with their behaviour change to reach their
            health, performance, or sporting goals... Due to my own health
            conditions including Ehlers Danlos Syndrome, I was/am also an avid
            consumer of the healthcare system. I started to notice my
            frustrations, and realised that my clients may also hate waiting,
            want to access care and information when they are diagnosed and want
            bite-size, practical options so they can make their own decisions
            and be in control of their own health.
          </p>
          <div [ngClass]="{ hidden: hidden }">
            <p>
              So, I started to experiment – I offered my VIP clients a special
              service – shorter, more frequent appointments; unlimited
              messaging; resource and recipe hubs – but this still involved a
              lot of my time, so it attracted a hefty fee – one that people were
              willing to pay for... This didn’t align with my philosophy that
              health should be accessible and affordable for everyone, so I had
              to search for a solution that allowed “me” or dietetic practice to
              be scaled. I started to use learning management systems and email
              software to drip feed information and offer communication when and
              where my clients needed it – closer to their event if they were
              athletes, when they were at an event if they were in the public
              eye, at the supermarket if they were choosing what to eat or cook.
              At the time I was also involved in the media – presenting on shows
              like the morning show, sunrise and the today show while spending
              my days doing corporate talks. The organisations I worked with
              would request ongoing work, but again without technology this is
              an expensive offering – and even the online courses would only be
              relevant to a fraction of the workforce. The final area I needed
              help with was keeping up with the specialty sports nutrition I was
              offering for English Channel and other extreme marathon swims or
              events. The demand exceeded what myself, and my team could deliver
              – so we needed to find a better way.
            </p>
            <p>
              These problems that we saw – those in clinical, corporate and
              sports led to searching for a technological solution to help us
              solve the problems we were having, and my strong desire to be able
              to provide health for all – to improve efficiency, effectiveness,
              affordability, and accessibility. Most importantly to give people
              choice. I didn’t find a solution, but some amazing people seemed
              to drop out of the sky to become my co-founders so we could build
              a solution. After 18-months of research, development, trialling
              and iterations (and welcoming some more amazing team members), I
              am so excited that Sophus will help us make the world a healthier
              place after our launch in August 2021.
            </p>
          </div>
          <p [ngClass]="{ hidden: !hidden }">
            <a (click)="hidden = !hidden">Keep reading...</a>
          </p>
          <div class="">
            <p class="mb-0"><b>Tara Diversi</b></p>
            <p class="mb-0">CEO & Founder</p>
          </div>
        </div>
        <div class="flex justify-center items-center">
          <div class="app-blob-background">
            <img
              class=""
              src="../../../../assets/images/tara-profile.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="app-curved-top-light-background pt-28"></div>
  <div class="bg-light pt-4 pb-32">
    <div class="app-container text-center">
      <h2 class="text-3xl mb-12">Our Leadership Team</h2>
      <div class="grid md:grid-cols-6 gap-8 md:gap-20">
        <div class="flex flex-col col-span-2 justify-center items-center">
          <img
            src="../../../../assets/images/tara.png"
            class="mb-3 object-contain"
          />
          <p class="mb-0"><b>Tara Diversi</b></p>
          <p class="mb-0">CEO & Founder</p>
          <div class="flex item-center justify-center text-lg">
            <a
              href="https://www.linkedin.com/in/taradiversi/"
              class="text-primary hover:text-tertiary cursor-pointer"
              target="_blank"
            >
              <ion-icon class="" name="logo-linkedin"></ion-icon>
            </a>
          </div>
        </div>
        <div class="flex flex-col col-span-2 justify-center items-center">
          <img
            src="../../../../assets/images/kate.png"
            class="mb-3 object-contain"
          />
          <p class="mb-0"><b>Kate Montgomery</b></p>
          <p class="mb-0">COO</p>
          <div class="flex item-center justify-center text-lg">
            <a
              href="https://www.linkedin.com/in/kate-montgomery-7a76527b/?originalSubdomain=au"
              class="text-primary hover:text-tertiary cursor-pointer"
              target="_blank"
            >
              <ion-icon class="" name="logo-linkedin"></ion-icon>
            </a>
          </div>
        </div>
        <div class="flex flex-col col-span-2 justify-center items-center">
          <img
            src="../../../../assets/images/jamie.png"
            class="mb-3 object-contain"
          />
          <p class="mb-0"><b>Jamieson Baker</b></p>
          <p class="mb-0">CTO</p>
          <div class="flex item-center justify-center text-lg">
            <a
              href="https://www.linkedin.com/in/jamieson-baker/"
              class="text-primary hover:text-tertiary cursor-pointer"
              target="_blank"
            >
              <ion-icon class="" name="logo-linkedin"></ion-icon>
            </a>
          </div>
        </div>
        <div
          class="flex flex-col md:col-start-2 col-span-2 justify-center items-center"
        >
          <img
            src="../../../../assets/images/amelia.png"
            class="mb-3 object-contain"
          />
          <p class="mb-0"><b>Amelia Bandera</b></p>
          <p class="mb-0">Dietitian & Product Manager</p>
          <div class="flex item-center justify-center text-lg">
            <a
              href="https://www.linkedin.com/in/ameliabandera/"
              class="text-primary hover:text-tertiary cursor-pointer"
              target="_blank"
            >
              <ion-icon class="" name="logo-linkedin"></ion-icon>
            </a>
          </div>
        </div>
        <div class="flex flex-col col-span-2 justify-center items-center">
          <img
            src="../../../../assets/images/christie.png"
            class="mb-3 object-contain"
          />
          <p class="mb-0"><b>Christie Johnson</b></p>
          <p class="mb-0">Dietitian</p>
          <div class="flex item-center justify-center text-lg">
            <a
              href="https://www.linkedin.com/in/christiepjohnson/"
              class="text-primary hover:text-tertiary cursor-pointer"
              target="_blank"
            >
              <ion-icon class="" name="logo-linkedin"></ion-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-primary pt-24">
    <div class="app-container text-white text-center">
      <h2 class="text-3xl mb-6">
        Interested in building the future of dietetics?
      </h2>
      <p class="text-xl max-w-xl mx-auto mb-0">
        <a
          href="mailto:hello@sophus.com.au"
          class="hover:text-tertiary text-white"
        >
          hello@sophus.com.au
        </a>
      </p>
    </div>
  </div>
  <div class="app-curved-bottom-primary-background pb-24"></div>

  <div class="py-28 overflow-hidden">
    <div class="app-container">
      <div class="grid md:grid-cols-4 gap-16">
        <div
          *ngFor="let logo of partnerLogos"
          class="flex items-center justify-center"
        >
          <img
            style="max-height: 15rem; max-width: 15rem"
            [src]="'../../../../assets/images/' + logo"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>

  <div id="cta" class="bg-light py-28">
    <div class="app-container">
      <div class="max-w-sm mx-auto text-center">
        <h2 class="text-3xl">Understand how Sophus can work for you</h2>
        <p>
          To find out more about our range of support and guidance programs, get
          in touch with us today. Enter your details below and we’ll be in
          touch.
        </p>
        <sophus-cta></sophus-cta>
      </div>
    </div>
  </div>

  <sophus-footer></sophus-footer>
</ion-content>
